import React, { lazy, Suspense } from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';
// This is temporary, don't import styles from other components
import * as styles from 'src/components/tournaments-calendar/tournaments-calendar.module.less';

const TournamentsCalendar = lazy(() => import('src/components/tournaments-calendar/tournaments-calendar'));

const isBrowser = typeof window !== 'undefined';

const CalendarPage = () => {
  return (
    <Layout>
      <SEO title="Tournaments Calendar" />
      <PrivateRoute>
        {isBrowser && (
          <div className={styles.container}>
            <Suspense fallback={<div />}>
              <TournamentsCalendar />
            </Suspense>
          </div>
        )}
      </PrivateRoute>
    </Layout>
  );
};

export default CalendarPage;
